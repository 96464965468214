import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { ThemeProvider, Styled } from 'theme-ui';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <ThemeProvider theme={{
  styles: {
    ul: {
      display: 'flex',
      listStyle: 'none',
      p: 0,
      m: 0,
      ml: 'auto'
    },
    p: {
      m: 0
    },
    a: {
      display: 'inline-block',
      fontSize: 14,
      fontWeight: 'bold',
      textDecoration: 'none',
      color: 'inherit',
      p: 3
    },
    root: {
      py: 3,
      display: 'flex'
    }
  }
}}>
    <Styled.root>
      {props.children}
    </Styled.root>
  </ThemeProvider>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "/"
      }}>{`System UI`}</a></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/theme"
        }}>{`Theme Specification`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/system-ui"
        }}>{`GitHub`}</a></li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      